var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from "../../elements/Icon";
import Tooltip from "../Tooltip";
import styles from "./index.module.scss";
var FieldDisplayItem = function (_a) {
    var label = _a.label, value = _a.value, tooltip = _a.tooltip, isColonLess = _a.isColonLess;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.label }, { children: [tooltip && (_jsx("div", __assign({ className: styles.tooltip }, { children: _jsx(Tooltip, __assign({ isGapless: true, content: tooltip, position: "left center", display: "inline-flex" }, { children: _jsx(Icon, { name: "info" }) })) }))), label] })), !isColonLess && _jsx("div", __assign({ className: styles.colon }, { children: ":" })), _jsx("div", __assign({ className: styles.value }, { children: value ? value : "-" }))] }));
};
export default FieldDisplayItem;
